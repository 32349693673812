/* stylelint-disable */
@import 'styles/variable.scss';

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.containerWrapper {
  background-color: #fcfcfc;
}

.container {
  @extend .GlobalNextAppContainer;
  display: flex;
  padding: 24px 24px 50px;

  p {
    margin: 0;
  }

  .row {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .mobile {
    display: none;
  }

  .main {
    flex: 1;

    p {
      margin: 0;
    }

    .headerTop {
      height: 50px;
      border-radius: 8px;
      border: 1px solid #e4e4e4;
      background: #fff;
      line-height: 50px;
      display: flex;
      padding: 0 0 0 15px;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .headerChild {
      margin-top: 0;
      border-radius: 0px 0px 8px 8px;
      background: #f6f6f6;
      border-top: 0;
    }

    .hasHeaderChild {
      border-radius: 8px 8px 0px 0px;
      margin-bottom: 0 !important;
    }

    .backLink {
      display: flex;
      color: #707070;
    }

    .back {
      padding-right: 11px;
      margin-top: 12px;
      font-weight: bold;
      color: #707070;
      cursor: pointer;
    }

    .bactText {
      cursor: pointer;
      padding-right: 20px;
      white-space: nowrap;
      color: #353535;
    }

    .line {
      color: #e4e4e4;
      padding: 0 20px 0 0px;
    }

    .time {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      color: #707070;
      padding: 8px 0 18px;
    }

    .noData {
      padding-top: 135px;
      min-height: calc(100vh - 260px);
      background: #ffffff;
      border: 0.5px solid #bcbcbc;
      border-radius: 15px;

      .noDataImg {
        margin: 0 auto;
        display: block;
      }

      .seeJob {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        width: 128px;
        height: 44px;
        line-height: 44px;
        background: #136fd3;
        border-radius: 10px;
        outline: none;
        border: none;
        cursor: pointer;
        margin: 28px auto;
        display: block;
        transition: background-color 0.2s;
        position: relative;

        a {
          display: block;
          color: #ffffff;
        }

        &:before {
          content: '';
          position: absolute;
          width: 50px;
          height: 200%;
          background-color: rgba(255, 255, 255, 0.6);
          transform: skew(45deg) translate3d(-200px, 0, 0);
        }

        &:hover::before {
          transition: ease-in-out 0.5s;
          transform: skew(45deg) translate3d(200px, 0, 0);
        }
      }
    }

    .detail {
      min-height: 134px;
      width: 100%;
      margin-bottom: 16px;
      font-size: 14px;
      letter-spacing: 1px;
      color: #353535;
      font-size: 14px;
      background: #fff;
      border-radius: 8px;
      transition: 0.3s;
      border: 1px solid #e4e4e4;

      &:hover {
        border: 1px solid #2378e5;
        box-shadow: 2px 4px 55px 0px rgba(0, 0, 0, 0.09);
        transition: 0.3s;

        .chatBox {
          transform: translateY(0) !important;
          transition: 0.3s;
        }
      }

      .header {
        padding: 0 0 0 24px;
        height: 47px;
        box-sizing: content-box;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(228, 228, 228, 0.3);

        .headerInfo {
          display: flex;
          align-items: center;
          overflow: hidden;
          flex: 1;
        }

        .name {
          vertical-align: middle;
          padding-left: 8px;
          font-size: 14px;
          letter-spacing: 1px;
          color: #707070;
          // flex: 1;
          max-height: 48px;
          text-align: left !important;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
        }

        .nameLine {
          color: #bcbcbc;
          padding: 0 10px;
          display: inline-block;
        }

        .avator {
          border-radius: 50%;
          border: 1px solid #e4e4e4;
          overflow: hidden;
          flex-shrink: 0;
        }

        .operator {
          padding: 6px 15px 0 0;
          display: flex;
          overflow: hidden;

          .chatBox {
            transform: translateY(120px);
            width: fit-content;
            height: 33px;
            line-height: 33px;
            background: #136fd3;
            border-radius: 6px;
            font-weight: 700;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
            padding: 0 4px;
            transition: 0.3s;

            :global {
              .css-6krx5u-MuiCircularProgress-root {
                color: #fff;
              }
            }

            .saveIcon {
              font-size: 24px;
              color: #fff;
              padding-right: 4px;
            }
          }

          .chatIng {
            width: fit-content;
          }

          .closedTip {
            line-height: 33px;
            font-size: 16px;
            align-items: center;
            letter-spacing: 1px;
            color: #353535;
          }
        }
      }

      .info {
        background: #fff;
        min-height: 86px;
        border-radius: 0px 0px 10px 10px;
        padding: 15px 0 16px 24px;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        letter-spacing: 1px;
        color: #353535;

        p {
          margin: 0;
        }

        .tag {
          font-weight: 400;
          font-size: 12px;
          padding: 2px 10px;
          color: #707070;
          display: inline-block;
          background: #f7f8fa;
          border-radius: 6px;
          margin: 5px 9px 0 0;
        }

        .leftContent {
          flex: 1;

          .developer {
            display: flex;

            .title {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-weight: 700;
              font-size: 18px;
              display: block;
              color: #353535;
              max-width: 260px;

              &:hover {
                color: #136fd3;
              }
            }
          }

          .salary {
            width: fit-content;
            font-weight: 700;
            font-size: 18px;
            color: #2378e5;
            padding: 4px 0;
            white-space: nowrap;
          }
        }

        .rightContent {
          width: 400px;
          margin-left: 30px;
          display: flex;

          .logo {
            width: 45px;
            height: 45px;
            border-radius: 5px;
            margin-right: 10px;
            margin-top: 10px;
            border: 1px solid #e0e0e0;
          }

          .companyInfo {
            flex: 1;
          }

          .company {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 14px;
            color: #353535;
            margin-top: 7px;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .interested {
      > .header {
        .name,
        .nameLine {
          font-size: 14px;
          text-align: center;
          color: #707070;
        }

        .jobrStatus {
          padding: 0 12px;
          height: 21px;
          font-size: 12px;
          line-height: 20px;
          color: #136fd3;
          margin-left: 13px;
          border-radius: 4px;
          border-width: 1px;
          border-style: solid;
          border-color: #2378e5;
          background: #fff;
          white-space: nowrap;
        }
      }

      .info {
        .leftContent {
          display: flex;

          .logo {
            width: 45px;
            height: 45px;
            border-radius: 5px;
            margin-right: 10px;
            margin-top: 3px;
          }

          .box {
            flex: 1;

            .developer {
              .title {
                max-width: 200px;
                line-height: 23px;
              }

              .salary {
                line-height: 24px;
                padding-bottom: 4px;
              }
            }
          }

          .companyName {
            font-size: 14px;
            line-height: 18px;
            color: #353535;
          }
        }

        .rightContent {
          margin-left: 60px;
          width: 370px;
          display: block;

          .time {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 1px;
            color: rgba(53, 53, 53, 1);
            margin: 0;
            padding: 0 0 10px 0;
            @extend .row;
            width: 354px;
            display: block;

            span {
              font-weight: 400;
              color: rgba(112, 112, 112, 1);
            }

            &:last-child {
              padding: 0;
            }
          }

          .address {
            font-weight: 400;
          }
        }
      }
    }

    .jobClosed {
      opacity: 0.5;
    }

    .disabledHover:hover {
      border-color: #e4e4e4;
      box-shadow: none;
    }

    .disabled {
      opacity: 0.5;
    }

    .disabled .developer .title:hover {
      color: #353535 !important;
    }

    .onHold {
      display: flex;
      padding: 12px 24px;
      align-items: center;
      gap: 10px;
      border-top: 1px solid #e4e4e4;
    }

    .onHoldText {
      color: #7D7D7D;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .mobileHeaderBox {
      width: calc(100vw - 130px);
      background: #fff;
    }

    .mobileHeaderMask {
      position: absolute;
      right: -2px;
      top: -1px;
      width: 28px;
      height: 48px;
      flex-shrink: 0;
      background: linear-gradient(268deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .page {
    display: flex;
    justify-content: center;

    :global {
      .MuiButtonBase-root {
        background-color: #fff;
        border: none;
        width: 40px;
        font-size: 16px;
        height: 40px;
        color: rgba(0, 0, 0, 0.87);
      }

      .MuiButtonBase-root:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      .MuiButtonBase-root.Mui-selected {
        background: #136fd3;
        color: white;
      }
    }
  }
}

.aside {
  width: 257px;
  margin-left: 16px;
  margin-bottom: 16px;

  .button {
    width: 225px;
    border: 1.5px solid #136fd3;
    border-radius: 8px;
    font-size: 14px;
    color: #136fd3;
    text-align: center;
    display: block;
    margin: 20px auto 0;
    height: 44px;
    outline: none;
    background: #fff;
    transition: 0.3s;
    cursor: pointer;
    overflow: hidden;

    a {
      display: block;
      color: #136fd3;
      line-height: 42px;
      height: inherit;
      font-weight: 700;
    }

    a:hover {
      background: #136fd3;
      color: white;
      transition: 0.2s;
    }
  }

  .resumeContainer {
    border-radius: 8px;
    border: 1px solid #e4e4e4;
    background: #fff;
    overflow: hidden;
  }

  .noticeSelect {
    :global {
      .MuiOutlinedInput-notchedOutline {
        border-width: 0.5px !important;
        border-color: #bcbcbc;
        border-radius: 8px;
      }
    }
  }

  .resume {
    background: #fff;
    padding: 16px;
    border-radius: 10px 10px 0 0;

    .user {
      display: flex;
      margin-bottom: 20px;
      justify-content: center;

      img {
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #e4e4e4;
      }

      .vipAvatar {
        position: relative;
        box-sizing: border-box;
        border-radius: 50%;
        border: 2px solid #ffc248;
        width: 64px;
        height: 64px;

        img {
          border-radius: none;
          border: none;
        }
      }

      .info {
        flex: 1;
        margin-left: 10px;
        margin-bottom: 12px;

        p {
          font-size: 18px;
          line-height: 23px;
          color: #353535;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 152px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #7d7d7d;
          display: inline-block;
        }

        i {
          font-style: normal;
          display: inline-block;
          padding: 0 4px;
          color: #7d7d7d;
        }
      }
    }

    :global {
      .MuiOutlinedInput-notchedOutline {
        border-width: 0.5px;
      }
    }
  }

  .type {
    background: linear-gradient(142deg, #edfbff 0%, #f3f8ff 100%);
    padding: 16px 24px;

    > li {
      list-style: none;
      margin-bottom: 16px;
      color: #353535;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: block;
        color: #353535;
        height: inherit;

        &:hover {
          color: #136fd3;
        }
      }
    }
  }

  .upload {
    margin-top: 16px;
    border-radius: 8px;
    border: 1px solid #e4e4e4;
    background: #fff;
    overflow: hidden;

    .uploadContainer {
      padding: 0 0 16px 0;

      ul {
        padding: 10px 16px 0 16px;

        li {
          display: flex;
          list-style: none;
          height: 26px;
          line-height: 26px;
          justify-content: space-between;

          a {
            // flex: 1;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: #353535;
            display: block;
          }

          .deleteIcoc {
            cursor: pointer;
            width: 20px;
            color: #646161;
            display: none;
          }

          &:hover {
            a {
              color: #136fd3;
              text-decoration: underline;
            }

            .deleteIcoc {
              display: block;
              color: #136fd3;
            }
          }
        }
      }

      .noMore {
        line-height: 18px;
        color: #353535;
        font-weight: 400;
        font-size: 14px;
        padding: 12px 0 0 16px;
        margin: 8px 0 0 0;
      }
    }
  }

  .header {
    font-weight: 700;
    font-size: 18px;
    color: #353535;
    padding: 16px 18px;
    border-bottom: 1px solid #e4e4e4;
  }

  .uploadResume {
    border-radius: 8px;
    border: 1px solid #92c2ff;
    background: linear-gradient(142deg, #edfbff 0%, #f3f8ff 100%);

    .header {
      border-bottom: 1px solid #c4ddfe;
    }
  }

  // .interstedBox {
  //   // padding-top: 11px;
  // }

  .cardList {
    display: block;
    padding: 14px;
    color: #353535;
    border-bottom: 1px solid #e0e0e0;

    &:hover {
      background: #f0f0f0;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    .salary {
      width: fit-content;
      color: #2378e5;
      text-align: right;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0.09px;
      padding-bottom: 6px;
    }

    .name {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 50%;
      }

      p {
        flex: 1;
        margin: 0;
        color: #121212;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;

        @extend .text-overflow;
      }

      .jobType {
        color: #515151;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.09px;
        flex: 1;

        @extend .text-overflow;
      }
    }

    .recruite {
      color: #7d7d7d;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0.09px;
      margin-top: 4px;
      padding-bottom: 4px;
    }
  }
}

@include msd {
  .container {
    padding: 0;

    .pcContainer,
    .aside {
      display: none;
    }

    .mobile {
      display: block;
      min-height: calc(100vh - 140px);
      overflow: hidden;
      background-color: #f6f6f6;

      .headerTop {
        border-radius: 0;
        width: 100vw;
        position: relative;

        .line {
          padding-right: 10px;
        }

        .bactText {
          padding-right: 10px;
        }

        .tabBox {
          width: calc(100vw - 114px);
          overflow: hidden;
          height: 50px;
        }

        .headerBox {
          display: flex;
          overflow-x: scroll;
          height: 66px;

          li {
            padding: 0 12px;
            list-style: none;
            color: #353535;
            position: relative;
            margin-right: 8px;
            cursor: pointer;

            &.active {
              &::after {
                content: ' ';
                position: absolute;
                width: 60px;
                height: 3px;
                background-color: #136fd3;
                left: 0;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }

      .mobileHeaderChild {
        background: #fff;
        border-radius: 8px;
        padding-left: 10px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #136fd3;
        position: absolute;
        right: 17px;
        top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid #e4e4e4;

        .arrow {
          font-size: 30px;
          color: #bcbcbc;
        }
      }

      .resumeBox {
        .title {
          height: 54px;
          background: #f0f0f0;
          border-radius: 10px 10px 0px 0px;
        }

        .item > li {
          height: 42px;
          line-height: 42px;

          &:hover {
            color: #136fd3;
          }
        }
      }
    }
  }
}

.uploadResumeBtn {
  margin-top: 16px;
}
